import React, { useState, ChangeEvent, useEffect } from 'react'
import {
    Box,
    Divider,
    FormGroup,
    InputLabel,
    MenuItem,
    Paper,
    ThemeProvider,
    Tooltip,
    Typography,
    createMuiTheme,
    makeStyles
} from '@material-ui/core'
import { connect } from 'react-redux'
import actionEditorSelectors from 'selectors/actionEditorSelectors'
import { css } from '@emotion/react'
import { AtlasIcon } from '@diligentcorp/atlas-react'
import {
    AtlasBlackNearVar,
    ColorBaseRichBlack,
    ColorEastBay,
    FontWeightLightBold,
    FontWeightRegular
} from 'assets/styles/variables'
import { AddActionDatePickerStyle } from './form/DateField'
import {
    AddActionEditActionButton,
    DatePicker,
    DialogActions,
    NewDialogTextField
} from 'components/shared/StyledComponents'
import { bindActionCreators } from 'redux'
import { getFilteredActions } from 'businesslayer/api/actions'
import { useCommitteesState } from 'components/contexts/committee-context'
import { getContacts } from 'businesslayer/networkrequests'
import { actions } from 'reducers/actionDashboardReducer'
import i18n from 'common/util/internationalization'
import { StyledAtlasButtonOld } from './ActionItemEditDialog/ActionItemEditDialog'

import RevealListIcon from 'assets/icons/RevealListIcon'
import { AtlasSelect } from 'components/atlas-material/select'
import { fontFamilyCss } from './MarkCompleteView/MarkCompleteView'
import { transientStorageManager } from 'businesslayer/minutesSessionStore'

interface ActionGridFilterProps {
    dateInputMask: string
    columns: any
    actions: any
    setPage: any
    setIsLoading: any
    setApplyedFilters: any
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.background.paper,
        height: '100%'
    },
    filter: {
        padding: '16px',
        alignItems: 'center',
        height: '92px',
        justifyContent: 'center'
    },
    textFieldContainer: {
        display: 'flex',
        alignItems: 'flex-start',
        minHeight: 50,
        placeItems: 'center'
    },
    dateField: {
        width: '100%',
        '& > div': {
            width: '100%'
        },
        '& > div > div': {
            width: '100%'
        },
        '& > *': {
            width: '100% !important'
        },
        [theme.breakpoints.down('md')]: {
            paddingTop: '12px',
            paddingBottom: '12px',
            '& > *': {
                width: '100% !important',
                display: 'contents'
            }
        }
    },
    textField: {
        minHeight: 40,
        marginTop: theme.spacing(1),
        '& p': {
            marginLeft: 0,
            marginRight: 0
        }
    },
    inputLabel: {
        marginTop: theme.spacing(1.3),
        fontFamily: 'Source Sans Pro',
        fontSize: '13px',
        fontWeight: FontWeightRegular,
        lineHeight: '16px',
        textAlign: 'left',
        color: ColorBaseRichBlack
    },
    filterBody: {
        gap: '8px',
        display: 'flex',
        flexDirection: 'column',
        padding: '16px',
        overflow: 'auto'
    },
    formControl: {
        width: '100%',
        fontFamilyCss,
        fontSize: '22px',
        fontWeight: FontWeightLightBold,
        lineHeight: '26px'
    },
    groupedField: {
        width: '48%',
        marginRight: 'auto'
    },
    groupedField1: {
        width: '48%',
        marginLeft: 'auto'
    },
    filterBtns: {
        display: 'flex',
        gap: '8px',
        float: 'right'
    },
    TitleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        height: '36px',
        placeItems: 'center'
    },
    iconGap: {
        '& span': {
            gap: '8px'
        }
    },
    ItemTitle: {
        fontFamily: 'Source Sans Pro',
        fontSize: '13px',
        fontWeight: FontWeightRegular,
        lineHeight: '16px',
        color: ColorBaseRichBlack
    },
    IconCss: {
        marginRight: '10px',
        '& path': {
            fill: 'transparent',
            stroke: ColorBaseRichBlack,
            color: ColorBaseRichBlack
        }
    },
    ResetButtonCss: {
        border: `1px solid ${ColorEastBay}`,
        '@media (max-width: 1200px)': {
            width: '85px'
        }
    },
    minutesTitleHintLabel: {
        fontFamily: 'Source Sans Pro',
        fontSize: '13px',
        fontWeight: FontWeightRegular,
        lineHeight: '16px',
        color: '#949494'
    },
    filterSubheader: {
        fontFamily: 'Source Sans Pro',
        fontSize: '16px',
        fontWeight: FontWeightRegular,
        lineHeight: '20px',
        color: ColorBaseRichBlack
    },
    dateInputTitle: {
        fontFamily: 'Source Sans Pro',
        fontSize: '16px',
        fontWeight: FontWeightLightBold,
        lineHeight: '20px',
        color: ColorBaseRichBlack,
        marginTop: 0
    },
    dialogActions: {
        display: 'flex',
        justifyContent: 'space-between',
        '@media (max-width: 1200px)': {
            justifyContent: 'center'
        }
    },
    applyButton: {
        [theme.breakpoints.down('sm')]: {
            whiteSpace: 'nowrap'
        }
    }
}))

const ActionGridFilter: React.FC<ActionGridFilterProps> = ({
    dateInputMask,
    actions,
    setPage,
    setIsLoading,
    // formData,
    setApplyedFilters
}) => {
    const dateTextMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
    const classes = useStyles()
    const [formData, setFormData] = useState<any>({})
    const { adminCommittees } = useCommitteesState()
    const [allContacts, setAllcontacts] = useState<any[]>([])
    useEffect(() => {
        ;(async () => {
            const data = await getContacts()
            const users = { ...data.contacts }
            setAllcontacts(Object.keys(users).map((key) => ({ ...users[key].attributes, id: key })))
        })()
    }, [])

    const [error, setError] = useState('')
    const [isApplyDisabled, setIsApplyDisabled] = useState(false)
    const handleDateChange = (name, date) => {
        if (
            (name === 'due_to_date' &&
                formData.due_from_date &&
                date &&
                date < formData.due_from_date) ||
            (name === 'due_from_date' &&
                formData.due_to_date &&
                date &&
                date > formData.due_to_date)
        ) {
            setError(i18n.t('INVALID_MINUTES_START_FINISH_DATES'))
            setIsApplyDisabled(true)
        } else {
            setError('')
            setIsApplyDisabled(false)
        }
        setFormData({ ...formData, [name]: date })
    }

    const clearFilters = () => {
        setFormData({
            committee_ids: '',
            search_title: '',
            status: '',
            due_from_date: '',
            due_to_date: '',
            assignee_ids: ''
        })
        setError('')
        setIsApplyDisabled(false)
    }

    const applyFilters = async () => {
        setIsLoading(true)
        const urlParams = new URLSearchParams()
        for (const key in formData) {
            if (formData[key]) {
                urlParams.append(key, formData[key])
            }
        }
        setPage(1)
        const actionsData = await getFilteredActions(urlParams)
        actions.loadActions(actionsData)
        setApplyedFilters(formData)
        setIsLoading(false)
    }

    const [isCommitteeExpanded, setCommitteeExpanded] = useState(false)
    const [isStatusExpanded, setStatusExpanded] = useState(false)
    const [isAssignedToExpanded, setAssignedToExpanded] = useState(false)
    const renderItemTitle = (item, itemkey, customClass = '') => (
        <Box key={itemkey} role="heading" aria-level={3} className={classes.TitleContainer}>
            <Typography aria-label={i18n.t(item)} className={`${classes.ItemTitle} ${customClass}`}>
                {i18n.t(item)}
            </Typography>
        </Box>
    )
    const MAXCOMMITTEENAME = 30
    const truncateCommitteeName = (title, limit = MAXCOMMITTEENAME) => {
        return title.length > limit ? title.slice(0, limit) + '...' : title
    }
    const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }

    dateInputMask = transientStorageManager.isDateInputMaskUS ? 'MM/dd/yyyy' : 'dd/MM/yyyy'

    const theme = createMuiTheme({
        overrides: {
            MuiTooltip: {
                tooltip: {
                    fontFamily: 'Source Sans Pro'
                }
            }
        }
    })
    return (
        <Paper
            datatest-id="action-grid-filter"
            className={classes.root}
            elevation={4}
            role="region"
            aria-labelledby={i18n.t('FILTERS')}>
            <Box role="heading" aria-level={2} id={i18n.t('FILTERS')} className={classes.filter}>
                <Typography className={classes.formControl} aria-label={i18n.t('FILTERS')}>
                    {i18n.t('FILTERS')}
                </Typography>

                <Typography
                    className={classes.filterSubheader}
                    aria-label={i18n.t('FILTER_OPTIONS.FILTERS_SUBHEADER')}>
                    {i18n.t('FILTER_OPTIONS.FILTERS_SUBHEADER')}
                </Typography>
            </Box>
            <Divider />

            <Box className={classes.filterBody}>
                <>
                    {renderItemTitle('COMMITTEE', 'committee')}

                    <AtlasSelect
                        datatest-id="committee_dropdown_filter"
                        role="combobox"
                        placeholder={i18n.t('SELECT_ALL_PLACEHOLDER')}
                        aria-labelledby={i18n.t('SELECT_ALL_PLACEHOLDER')}
                        name="committee_ids"
                        data-analytics="MM-FilterCommittee"
                        select
                        value={formData.committee_ids}
                        variant="outlined"
                        size="small"
                        onChange={(e) => {
                            handleInputChange(e)
                            setIsApplyDisabled(false)
                        }}
                        SelectProps={{
                            IconComponent: () => (
                                <RevealListIcon className={classes.IconCss}></RevealListIcon>
                            ),
                            onOpen: () => {
                                setCommitteeExpanded(true)
                                setIsApplyDisabled(true)
                            },
                            onClose: () => {
                                setCommitteeExpanded(false)
                                setIsApplyDisabled(false)
                            },
                            MenuProps: {
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left'
                                },
                                transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'left'
                                },
                                getContentAnchorEl: null
                            }
                        }}
                        aria-expanded={isCommitteeExpanded}>
                        {adminCommittees.map((committee) => (
                            <MenuItem
                                className="dropDownFocus"
                                key={committee.id}
                                value={committee.id}>
                                {committee.name.length > MAXCOMMITTEENAME ? (
                                    <ThemeProvider theme={theme}>
                                        <Tooltip title={committee.name} placement="top-start">
                                            <div>{truncateCommitteeName(committee.name)}</div>
                                        </Tooltip>
                                    </ThemeProvider>
                                ) : (
                                    <div>{committee.name}</div>
                                )}
                            </MenuItem>
                        ))}
                    </AtlasSelect>
                </>

                <>
                    {renderItemTitle('FILTER_OPTIONS.MINUTES_DOCUMENT', 'text')}
                    <Typography
                        aria-label={i18n.t('HINT_TEXT_SEARCH')}
                        className={classes.minutesTitleHintLabel}>
                        {i18n.t('HINT_TEXT_SEARCH')}
                    </Typography>
                    <FormGroup row>
                        <NewDialogTextField
                            name="search_title"
                            data-analytics="MM-FilterTitle"
                            datatest-id="search_title"
                            aria-label={i18n.t('FILTER_OPTIONS.MINUTES_DOCUMENT')}
                            placeholder={i18n.t('MINUTES_DOCUMENT_TITLE_PLACEHOLDER')}
                            value={formData.search_title}
                            variant="outlined"
                            size="small"
                            onChange={handleInputChange}
                            fullWidth></NewDialogTextField>
                    </FormGroup>
                </>

                <>
                    {renderItemTitle('FILTER_OPTIONS.ACTION_STATUS', 'status')}
                    <FormGroup row role="group" aria-labelledby={i18n.t('SELECT_ALL_PLACEHOLDER')}>
                        <AtlasSelect
                            datatest-id="status_dropdown_filter"
                            placeholder={i18n.t('SELECT_ALL_PLACEHOLDER')}
                            aria-labelledby={i18n.t('SELECT_ALL_PLACEHOLDER')}
                            data-analytics="MM-FilterStatus"
                            aria-haspopup="listbox"
                            role="combobox"
                            select
                            name="status"
                            value={formData.status}
                            variant="outlined"
                            size="small"
                            onChange={handleInputChange}
                            SelectProps={{
                                IconComponent: () => (
                                    <RevealListIcon className={classes.IconCss}></RevealListIcon>
                                ),
                                onOpen: () => setStatusExpanded(true),
                                onClose: () => setStatusExpanded(false)
                            }}
                            fullWidth
                            aria-expanded={isStatusExpanded}>
                            <MenuItem className="dropDownFocus" value="completed">
                                <div>{i18n.t('REVIEW_COMPLETED')}</div>
                            </MenuItem>
                            <MenuItem className="dropDownFocus" value="overdue">
                                <div>{i18n.t('OVERDUE_ACTION_LIST_LABEL')}</div>
                            </MenuItem>
                            <MenuItem className="dropDownFocus" value="pending">
                                <div>{i18n.t('PENDING')}</div>
                            </MenuItem>
                        </AtlasSelect>
                    </FormGroup>
                </>

                <>
                    {renderItemTitle('ASSIGNED_TO', 'assigned_to')}
                    <AtlasSelect
                        datatest-id="assigned_to_dropdown_filter"
                        role="combobox"
                        aria-labelledby={i18n.t('ASSIGNED_TO')}
                        data-analytics="MM-FilterAssignedTo"
                        aria-haspopup="listbox"
                        select
                        name="assignee_ids"
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={formData.assignee_ids}
                        placeholder={i18n.t('SELECT_ALL_PLACEHOLDER')}
                        onChange={handleInputChange}
                        SelectProps={{
                            IconComponent: () => (
                                <RevealListIcon className={classes.IconCss}></RevealListIcon>
                            ),
                            onOpen: () => setAssignedToExpanded(true),
                            onClose: () => setAssignedToExpanded(false)
                        }}
                        aria-expanded={isAssignedToExpanded}>
                        {Array.from(allContacts)?.map((user) => (
                            <MenuItem className="dropDownFocus" value={user.id} key={user.id}>
                                {user?.displayName?.length > MAXCOMMITTEENAME ? (
                                    <ThemeProvider theme={theme}>
                                        <Tooltip
                                            title={user.displayName ?? ''}
                                            placement="top-start">
                                            <div>
                                                {truncateCommitteeName(user.displayName ?? '')}
                                            </div>
                                        </Tooltip>
                                    </ThemeProvider>
                                ) : (
                                    <div>{truncateCommitteeName(user.displayName ?? '')}</div>
                                )}
                            </MenuItem>
                        ))}
                    </AtlasSelect>
                </>

                <>
                    {renderItemTitle('DUE_DATE', 'due_date', classes.dateInputTitle)}
                    <InputLabel className={classes.inputLabel} htmlFor="due_from_date">
                        {i18n.t('FROM')}
                    </InputLabel>
                    <DatePicker
                        datatest-id="due_date_from"
                        data-analytics="MM-FilterDueFromDate"
                        aria-describedby="due_from_date_helper"
                        role="datepicker"
                        className={css`
                            ${AddActionDatePickerStyle}
                        `.toString()}
                        keyboard
                        id="due_from_date"
                        variant="outlined"
                        disableOpenOnEnter
                        size="small"
                        format={dateInputMask}
                        placeholder={dateInputMask.toUpperCase()}
                        onChange={(date) => handleDateChange('due_from_date', date)}
                        mask={(value) => (value ? dateTextMask : [])}
                        keyboardIcon={<AtlasIcon name="calendar" color={AtlasBlackNearVar} />}
                        value={formData.due_from_date || null}
                        error={false}
                        helperText={''}
                    />

                    <InputLabel className={classes.inputLabel} htmlFor="due_to_date">
                        {i18n.t('SEND_INVITATION_TO')}
                    </InputLabel>

                    <DatePicker
                        datatest-id="due_date_to"
                        data-analytics="MM-FilterDueToDate"
                        aria-describedby="due_to_date_helper"
                        role="datepicker"
                        className={css`
                            ${AddActionDatePickerStyle}
                        `.toString()}
                        keyboard
                        id="due_to_date"
                        variant="outlined"
                        disableOpenOnEnter
                        size="small"
                        format={dateInputMask}
                        placeholder={dateInputMask.toUpperCase()}
                        onChange={(date) => handleDateChange('due_to_date', date)}
                        mask={(value) => (value ? dateTextMask : [])}
                        keyboardIcon={<AtlasIcon name="calendar" color={AtlasBlackNearVar} />}
                        value={formData.due_to_date || null}
                        error={false}
                        helperText={''}
                    />
                    {error && (
                        <Typography color="error" variant="body2">
                            {error}
                        </Typography>
                    )}
                </>
            </Box>
            <Divider />
            <DialogActions className={classes.dialogActions}>
                <Box>
                    <AddActionEditActionButton
                        aria-label={i18n.t('RESET_FILTER')}
                        datatest-id="clear-filters"
                        disableFocusRipple
                        disableRipple
                        variant="text"
                        color="primary"
                        size="small"
                        className={classes.ResetButtonCss}
                        onClick={clearFilters}>
                        {i18n.t('RESET_FILTER')}
                    </AddActionEditActionButton>
                </Box>
                <Box>
                    <StyledAtlasButtonOld
                        aria-label={i18n.t('APPLY')}
                        datatest-id="apply-filters"
                        variant="contained"
                        disabled={isApplyDisabled}
                        color="primary"
                        style={{
                            border: isApplyDisabled ? 'transparent' : `1px solid ${ColorEastBay}`
                        }}
                        className={`${classes.applyButton} custom-class`}
                        onClick={applyFilters}>
                        {i18n.t('APPLY')}
                    </StyledAtlasButtonOld>
                </Box>
            </DialogActions>
        </Paper>
    )
}

const mapStateToProps = (state: any) => {
    return {
        dateInputMask: actionEditorSelectors.dateInputMask(state.actionEditorReducer)
    }
}
const mapDispatchToProps = (dispatch) => {
    const { loadActions } = actions

    return {
        actions: bindActionCreators({ loadActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionGridFilter)
